import { Injectable, inject } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { MailtasticAuthorizationDetails } from '@model/enums/mailtastic-authorization-details.enum';
import { IntegrationTokenData } from '@model/interfaces/integrations.interface';
import { TranslocoService } from '@ngneat/transloco';
import { AzureSyncService } from '@services/azure-sync/azure-sync.service';
import { HUBSPOT_PROVIDER, PARDOT_PROVIDER } from '@services/crm/crm-service.interface';
import { CrmService } from '@services/crm/crm.service';
import { GoogleSyncService } from '@services/google-sync/google-sync.service';
import { CustomOperators } from '@shared/operators/custom-operators';
import { map, Observable, take, tap } from 'rxjs';
import { AccountService } from 'src/app/services/account/account.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  private accountService = inject(AccountService);
  private authService = inject(AuthService);
  private azureSyncService = inject(AzureSyncService);
  private crmService = inject(CrmService);
  private googleSyncService = inject(GoogleSyncService);
  private operator = inject(CustomOperators);
  private router = inject(Router);
  private translate = inject(TranslocoService);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.isAuthenticated()) {
      /**
       * Used for create integration connection by non MT admin
       * Allows non MT admin to create integration connection by auth token of MT admin.
       * MT admin will send an email to create integration connection with link including auth token
       * The non-MT admin will open the link and click on create connection where he first needs to login into workspace account and the auth URL and be redirected to the MT app's sync page.
       * The sync page will redirect the non MT admin to create integration connection page after storing data in localstorage
       */
      if (this.authService.getNonMTadminAccessData().mtAdminAuthToken && this.isPageForNonMTAdmin(route, state)) {
        return this.getAccessTokenForNonMtAdmin(route, state).pipe(
          tap(data => {
            if (data.accessToken) {
              const { mtAdminAuthToken, integrationType, nonMTAdminName } = this.authService.getNonMTadminAccessData();

              if (mtAdminAuthToken && integrationType) {
                const accessData = JSON.stringify({
                  accessTokenKey: data.accessToken,
                  accountId: data.accountId,
                  expiresIn: data.expiresIn,
                  integrationType,
                  mtAdminAuthToken,
                  nonMTAdminName,
                  refreshToken: data.refreshToken
                });

                this.authService.setKeyInLocalstorage(MailtasticAuthorizationDetails.accessData, accessData);

                void this.router.navigate([
                  `/create-connection/${mtAdminAuthToken}/${integrationType}/${nonMTAdminName}/${this.translate.getActiveLang()}`
                ]);
              } else {
                void this.router.navigate(['/login']);
              }
            } else {
              void this.router.navigate(['/login']);
            }
          }),
          map(() => false)
        );
      } else {
        this.authService.removeLocalStorage();
        // Pendo `isReady` function will not work when Adblockers extension will activated
        if (typeof pendo.isReady === 'function' && pendo.isReady()) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
          (pendo as any).clearSession();
        }
        void this.router.navigate(['/login']);
        return false;
      }
    }

    return this.authService.authorizationScope$.pipe(
      take(1),
      map(authorizationScope => {
        // Check signup tour finished
        if (authorizationScope.isUserLoggedInFirstTime) {
          // Allow URL's without finishing signup tour
          const allowedURLs = ['/tour', '/account/select'];

          // Redirect other URL's
          if (!allowedURLs.includes(state.url)) {
            void this.router.navigate(['/tour']);
          }

          return allowedURLs.includes(state.url);
        } else {
          // Redirect tour URL or connect-server to dashboard if isUserLoggedInFirstTime false
          if (
            state.url === '/tour' ||
            (state.url.includes('/integrations/connect-server') &&
              !this.authService.decodeToken().isUserLoggedInFirstTime)
          ) {
            void this.router.navigate(['/dashboard']);
          }

          const accountData = this.accountService.getUserAccountData();

          // Should always have email and company name
          // Pendo `isReady` function will not work when Adblockers extension will activated
          if (typeof pendo.isReady === 'function' && accountData.email && accountData.companyName) {
            // Initialize pendo
            if (!pendo.isReady()) {
              pendo.initialize({
                visitor: {
                  id: accountData.email
                },
                account: {
                  id: accountData.companyName
                }
              });
            }
            // When initialized, check if visitor or account needs to be updated
            else if (pendo.getVisitorId() !== accountData.email || pendo.getAccountId() !== accountData.companyName) {
              pendo.updateOptions({
                visitor: {
                  id: accountData.email
                },
                account: {
                  id: accountData.companyName
                }
              });
            }
          }

          return true;
        }
      })
    );
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(childRoute, state);
  }

  /**
   * Gets the access token for non MT admin to create integration connection
   * @param route - The route
   * @param state - The state
   * @returns The Observable of integration token data
   */
  private getAccessTokenForNonMtAdmin(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<IntegrationTokenData> {
    if (state.url.includes('/googlesync')) {
      return this.googleSyncService.getGoogleToken(route.queryParams['code'] as string).pipe(
        this.operator.filterNullish(),
        map(data => ({ accessToken: data }))
      );
    } else if (state.url.includes('/o365/create')) {
      return this.azureSyncService
        .getToken(state.url.split('code=')[1], 'international')
        .pipe(map(data => ({ accessToken: data.accessToken, refreshToken: data.refreshToken })));
    } else if (state.url.includes('/pardot/sync')) {
      return this.crmService.getPardotToken(route.queryParams['code'] as string, PARDOT_PROVIDER).pipe(
        map(data => ({
          accessToken: data.access_token,
          accountId: data.id,
          expiresIn: data.exp,
          refreshToken: data.refresh_token
        }))
      );
    } else {
      return this.crmService.getHubspotToken(route.queryParams['code'] as string, HUBSPOT_PROVIDER).pipe(
        map(data => ({
          accessToken: data.access_token,
          expiresIn: data.expires_in,
          refreshToken: data.refresh_token
        }))
      );
    }
  }

  /**
   * Checks that page without auth token is for non MT admin
   * @param route - The route
   * @param state - The state
   * @returns The status
   */
  private isPageForNonMTAdmin(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isO365Page = !!(state.url.includes('/o365/create') && state.url.split('code=')[1]);
    const isCodeExist = !!route.queryParams['code'];
    const isSyncRoute = ['/googlesync', '/hubspot/sync', '/pardot/sync'].some(page => state.url.includes(page));

    return isO365Page || (isCodeExist && isSyncRoute);
  }
}
